<template>
  <div class="wrapper">
    <h5>
      Lances automáticos para o leilão
    </h5>
    <div class="m-b">Você visualizará nesta lista todos os lances automáticos configurados para este leilão.
    </div>

    <div class="filters row m-b-xs font-11 justify-between">
      <div class="col text-grey-7" style="max-width: 170px; line-height: 20px">
        {{ filteredServerData.length }} configurado{{ filteredServerData.length > 1 ? 's' : '' }}
        <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="table.loading"/>
      </div>
      <div class="col m-l-lg" style="line-height: 20px; max-width: 500px">
        <div class="row">
          <div class="col"></div>
        </div>
      </div>
      <div class="col" style="min-width: 300px;"></div>

      <div class="col" style="">
        <erp-s-field
            class="font-10"
            view="lr"
            label="Buscar:"
        >
          <helper-input-btn>
            <erp-input simple-border v-model="table.filters.pesquisa"/>
            <a class="m-l-xs text-black">IR</a>
          </helper-input-btn>
        </erp-s-field>
      </div>
      <div class="col m-l m-b-n" style="max-width: 110px; text-align: right">
        <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8"/>
      </div>
    </div>
    <div class="erp-list no-pad">
      <u-table
          ref="table"
          color="primary"
          :data="filteredServerData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr slot="body" slot-scope="props" :props="props">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td key="id" :props="props">
            <div>{{ props.row.id }}</div>
          </u-td>
          <u-td key="apelido" :props="props">
            <div>{{ props.row.apelido }}</div>
          </u-td>
          <u-td key="ip" :props="props">
            <div>{{ props.row.ip }}</div>
          </u-td>
          <u-td key="parcelado" :props="props">
            <div v-if="props.row.parcelado"><strong class="text-warning">Sim, {{props.row.parcelas}} parcela(s)</strong></div>
            <div v-else>Não</div>
          </u-td>
          <u-td key="numlote" :props="props">
            {{ props.row.numeroLote }}
          </u-td>
          <u-td key="valormaximo"
                :props="props">
            R$ {{ props.row.valorLimite | moeda }}
          </u-td>
          <u-td key="active"
                :props="props">
            <div v-if="props.row.active" class="text-positive">Sim</div><div v-else>Não</div>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <default-list-menu
                    @excluir="confirmaExcluir(props.row)"
                />
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
    </div>
  </div>
</template>

<script>
import {UCheckbox, UTable, UTd, UTh, UTr, LocalStorage} from 'uloc-vue'
import {EBtnTableOptions, ErpSField, ErpInput, HelperInputBtn} from 'uloc-vue-plugin-erp'
import {listaConfiguracoesLancesAutomaticos, excluirConfiguracaoLanceAutomatico} from '@/domain/leiloes/services'
import MenuOptions from '../../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu'
import AlteracaoStatusLoteMixin from '../include/AlteracaoStatusLoteMixin'
import listOptions from '../../windows/listOptions'
import DefaultMixin from '@/components/leiloes/mixin'
import tableColumnsCache from '@/utils/tableColumnsCache'

const listStorage = tableColumnsCache('leilao.lances-automaticos.list', [
  {label: 'id', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Apelido Arrematante', name: 'apelido', active: true, sortable: true, ordering: 1},
  {label: 'IP', name: 'ip', active: true, sortable: true, ordering: 1},
  {label: 'Parcelado', name: 'parcelado', active: true, sortable: true, ordering: 1},
  {label: 'Nº Lote', name: 'numlote', active: true, sortable: true, ordering: 1},
  {label: 'Valor Máximo', name: 'valormaximo', active: true, sortable: true, ordering: 1},
  {label: 'Ativo', name: 'active', active: true, sortable: true, ordering: 1}
], 4)

let filters = {
  pesquisa: null
}

export default {
  name: 'ListaConfiguracaoLancesAutomaticos',
  mixins: [AlteracaoStatusLoteMixin, DefaultMixin],
  inject: ['page', 'erplayout'],
  props: {},
  data() {
    return {
      leilao: this.page.leilao,
      listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 500
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            required: o.required || null,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  components: {
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    DefaultListMenu,
    ErpSField,
    ErpInput,
    HelperInputBtn
  },
  mounted() {
    this.page.setActive('autolan')
    console.log(this.leilao)
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    filteredServerData() {
      let data = this.table.serverData.slice()

      let busca = this.table.filters.pesquisa
      if (busca && String(busca).length > 0) {
        console.log(busca)
        data = data.filter(lt => {
          return String(lt.apelido).toLowerCase().indexOf(busca) > -1
              || String(lt.numeroLote).toLowerCase().indexOf(busca) > -1
        })
      }
      return data
    }
  },
  methods: {
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request({pagination, filter}) {
      this.table.loading = true
      this.table.selected = []
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${filter}&leilao=${this.leilao.id}`)

      listaConfiguracoesLancesAutomaticos(this.leilao.id, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result || []

            this.table.loading = false
            this.loadedData()
          })
          .catch(error => {
            this.loading = false
          })
    },
    listOptions: listOptions,
    loadedData() {
      this.$emit('loaded', this.table.serverData)
    },
    excluir (id) {
      excluirConfiguracaoLanceAutomatico(id)
          .then(({data}) => {
            this.load()
            this.$uloc.notify({color: 'positive', message: 'Configuração de lance removida com sucesso!'})
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    confirmaExcluir ({id, apelido}) {
      this.$uloc.dialog({
        title: 'Atenção',
        message: `Tem certeza que deseja excluir a configuração do arrematante ${apelido}?`,
        cancel: true,
        color: 'secondary'
      }).then(data => {
        this.$uloc.notify({color: 'warning', message: 'Excluindo, aguarde'})
        this.excluir(id)
      }).catch(() => {
      })
    },
  }
}
</script>

<style lang="stylus"></style>
